* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.pointer {
  cursor: pointer;
}

#excel-export-table {
  table,
  th,
  td {
    border: 1px solid black;
  }
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 120px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Add Animation */
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* The Close Button */
.close {
  position: absolute;
  top: 30px;
  right: 50px;
  color: #f1f1f1 !important;
  font-size: 60px !important;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }

  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 150px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.9); /* Black w/ opacity */
  }

  .close {
    position: absolute;
    top: 35px;
    right: 0px;
    color: #f1f1f1 !important;
    font-size: 40px !important;
    font-weight: bold;
    transition: 0.3s;
  }
}
//phu
.dialogImport .MuiPaper-root {
  width: 100%;
  overflow: unset;
}
.btn-browse {
  background-color: rgb(67, 160, 71);
  color: white;
  padding: 6px 16px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.dialogConfirmImport .MuiPaper-root {
  padding: 6px 16px;
}
.btn-not-allow {
  background-color: #ff0000 !important;
  color: #fff !important;
  cursor: not-allowed !important;
}
.btn-allow {
  background-color: rgb(67, 160, 71) !important;
  color: #fff !important;
}
.MuiCard-root {
  overflow: unset !important;
}
.MuiInputLabel-outlined {
  z-index: 0 !important;
}
